<template>
    <div style="height: 100%">
        <Header :menus="menus"></Header>
        <RightLian />

        <div class="page-section1">
            <div>
                <el-carousel height="1020px" indicator-position="outside">
                    <el-carousel-item>
                        <div>
                            <div
                                class="chanPingShiPeiList"
                                v-for="(item, key2) in cpListOne"
                                :key="key2"
                            >
                                <el-card class="box-card" style="height: 980px">
                                    <p class="pName" style="font-size: 36px">
                                        {{ item.name }}
                                    </p>

                                    <el-button
                                        class="xuanzhuan"
                                        type="primary"
                                        v-if="item.type === 1"
                                        style="font-size: 36px"
                                        >新品
                                    </el-button>
                                    <el-button
                                        class="xuanzhuan"
                                        type="primary"
                                        style="font-size: 36px"
                                        v-else
                                        >热销
                                    </el-button>

                                    <div style="width: 100%; height: 300px">
                                        <img
                                            :src="item.thumb_img"
                                            style="width: 100%; height: 300px; margin-top: 20px"
                                        />
                                    </div>
                                    <div style="margin-top: 80px">
                                        <div
                                            v-for="(item1, key1) in item.config"
                                            :key="key1"
                                        >
                                            <el-button
                                                type="primary"
                                                class="btn2"
                                                >{{ item1 }}</el-button
                                            >
                                        </div>
                                        <el-button
                                            type="text"
                                            class="Pxiangq"
                                            style="
                        margin-top: 40px;
                        margin-left: 16%;
                        font-size: 40px;
                      "
                                            @click="chakan(item)"
                                        >
                                            查看详情 > >
                                        </el-button>
                                    </div>
                                </el-card>
                            </div>
                        </div>
                    </el-carousel-item>
                    <el-carousel-item>
                        <div>
                            <div
                                class="chanPingShiPeiList"
                                v-for="(item, key2) in cpListTwo"
                                :key="key2"
                            >
                                <el-card class="box-card" style="height: 980px">
                                    <p class="pName" style="font-size: 36px">
                                        {{ item.name }}
                                    </p>

                                    <el-button
                                        style="font-size: 36px"
                                        class="xuanzhuan"
                                        type="primary"
                                        v-if="item.type === 1"
                                    >
                                        新品
                                    </el-button>
                                    <el-button
                                        style="font-size: 36px"
                                        class="xuanzhuan"
                                        type="primary"
                                        v-else
                                    >
                                        热销
                                    </el-button>

                                    <div style="width: 100%; height: 300px">
                                        <img
                                            :src="item.thumb_img"
                                            style="width: 100%; height: 300px; margin-top: 20px"
                                        />
                                    </div>
                                    <div style="margin-top: 80px">
                                        <div
                                            v-for="(item1, key1) in item.config"
                                            :key="key1"
                                        >
                                            <el-button
                                                type="primary"
                                                class="btn2"
                                                >{{ item1 }}</el-button
                                            >
                                        </div>
                                        <el-button
                                            type="text"
                                            class="Pxiangq"
                                            style="
                        margin-top: 40px;
                        margin-left: 16%;
                        font-size: 40px;
                      "
                                            @click="chakan(item)"
                                        >
                                            查看详情 > >
                                        </el-button>
                                    </div>
                                </el-card>
                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>

            <div class="doc-container">
                <div style="margin-left: 60%; margin-bottom: 40px">
                    <el-input
                        placeholder="请输入关键字搜索"
                        v-model="shuru"
                        style="width: 400px"
                    />
                    <el-button
                        type="primary"
                        style="
              margin-left: 10px;
              height: 80px;
              width: 100px;
              font-size: 24px;
            "
                        >搜索</el-button
                    >
                </div>

                <div style="">
                    <div class="divBorder">
                        <div
                            v-for="(item, index) in yulanList.imgs"
                            :key="index"
                            class="divImg"
                        >
                            <img :src="item" style="width: 100%" />
                        </div>
                    </div>

                    <div class="divConfig">
                        <div style="margin-bottom: 50px">
                            <el-button
                                type="primary"
                                style="margin: 10px"
                                class="chanpxianhq"
                                >产品详情</el-button
                            >
                            <p style="text-align: center; font-size: 46px">
                                {{ yulanList.name }}
                            </p>
                        </div>

                        <p
                            v-for="(item, index) in yulanList.config"
                            :key="index"
                            class="divImg1"
                            style="height: 90px; margin-left: 50px; font-size: 36px"
                        >
                            {{ item }}
                        </p>
                    </div>

                    <div class="divBorder">
                        <div
                            v-html="yulanList.content"
                            style="text-align: center; font-size: 36px"
                        ></div>
                    </div>
                </div>
                <div>
                    <div class="zhanKaiTopShiPei" style="margin-top: 120px">
                        <img
                            src="../assets/wxin.png"
                            style="width: 10%; margin-left: 20px"
                        />
                        <span
                            style="margin-left: 20px; line-height: 40px; font-size: 46px"
                            >联系方式</span
                        >
                    </div>
                    <div class="biankuangShiPei">
                        <div style="float: left; margin-left: 2%" class="bksp">
                            <p>售前咨询：杨洋</p>
                            <p>联系电话：18665393712</p>
                            <p>邮箱：yangyang@wif.ink</p>
                        </div>
                        <div style="">
                            <img src="../assets/tu.jpg" style="width: 50%" />
                            <p class="pw">长按保存图片，扫码加微信</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="page-section2">
            <div class="doc-container">
                <div class="left-contents" style="margin-top: 1.4rem">
                    <div class="zhanKaiTop" @click="chanp">
                        <img
                            src="../assets/chanpfl.png"
                            style="width: 10%; margin-left: 20px"
                        />
                        <span style="margin-left: 20px">产品分类</span>
                    </div>
                    <div style="height: 220px">
                        <el-collapse accordion v-model="a1" class="a1">
                            <div
                                v-if="fenLeiList.length === 0"
                                style="
                  width: 130px;
                  height: 100px;
                  margin: 0 auto;
                  margin-top: 20px;
                "
                            >
                                暂未上传产品分类
                            </div>
                            <div v-for="(item, key) in fenLeiList" :key="key">
                                <el-collapse-item :name="key">
                                    <template slot="title">
                                        <el-button
                                            type="text"
                                            style="margin-left: 10px"
                                            @click.native="dianjiShang(item)"
                                            >{{ item.name }}</el-button
                                        >
                                    </template>
                                    <div
                                        v-if="
                                            item.product_list === '' ||
                                                item.product_list ===
                                                    undefined ||
                                                item.product_list === null ||
                                                item.product_list.length === 0
                                        "
                                    >
                                        <p
                                            style="margin-left: 20px; font-size: 14px"
                                        >
                                            暂未添加产品
                                        </p>
                                    </div>
                                    <div
                                        v-for="(item1,
                                        key1) in item.product_list"
                                        @click="xuanze(item1, item)"
                                        :key="key1"
                                    >
                                        <el-button
                                            v-if="
                                                item1.product_id != product_id
                                            "
                                            type="text"
                                            class="text1"
                                            style="margin-left: 20px"
                                            >{{ item1.name }}
                                        </el-button>
                                        <el-button
                                            v-else
                                            type="text"
                                            class="text1"
                                            style="margin-left: 20px; color: #000000"
                                            >{{ item1.name }}
                                        </el-button>
                                    </div>
                                </el-collapse-item>
                            </div>
                        </el-collapse>
                    </div>

                    <div class="zhanKaiTop" style="margin-top: 120px">
                        <img
                            src="../assets/wxin.png"
                            style="width: 10%; margin-left: 20px"
                        />
                        <span style="margin-left: 20px">联系方式</span>
                    </div>
                    <div class="biankuang">
                        <p>售前咨询：杨洋</p>
                        <p>联系电话：18665393712</p>
                        <p>邮箱：yangyang@wif.ink</p>
                        <div>
                            <img src="../assets/tu.jpg" />
                        </div>
                    </div>
                </div>

                <div class="doc-content md">
                    <div>
                        <div class="divDocLeft">
                            <div style="width: 100%; height: 400px">
                                <div class="oneImgDiv">
                                    <img
                                        :src="oneImg"
                                        style="width: 100%; height: 100%"
                                    />
                                </div>
                                <div style="margin-top: 20px">
                                    <i
                                        class="el-icon-arrow-left"
                                        style="
                      position: relative;
                      top: -10px;
                      float: left;
                      font-size: 24px;
                      margin-top: 15px;
                    "
                                        @click="imgLeft"
                                    />
                                    <div class="donwLoadList3">
                                        <div
                                            v-for="(item,
                                            index) in downloadList2"
                                            :key="index"
                                            class="donwLoadList"
                                            :style="imgStyle"
                                            @click="changeImg(item, index)"
                                        >
                                            <img
                                                :src="item"
                                                :class="
                                                    index === dangqianIndex
                                                        ? 'imgSec'
                                                        : ''
                                                "
                                                style="width: 57px; height: 37px"
                                            />
                                        </div>
                                    </div>
                                    <i
                                        @click="imgRight"
                                        class="el-icon-arrow-right"
                                        style="
                      position: relative;
                      top: -49px;
                      left: 400px;
                      float: left;
                      font-size: 24px;
                      margin-top: 15px;
                    "
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="divDocRight">
                            <p class="rightOneP">{{ yulanList.name }}</p>

                            <div
                                style="
                  width: 90%;
                  height: 10px;
                  margin-left:10%;
                  border-bottom: 1px solid #e5e5e5;
                "
                            ></div>

                            <p style="margin-top: 10px">{{ yulanList.desc }}</p>

                            <div class="xiangqingDiv">
                                <span
                                    class="xiangqDiv"
                                    v-for="(item,
                                    index) in yulanList.core_config"
                                    :key="index"
                                    style="line-height:50px;"
                                >
                                    {{ item.name }}：{{ item.value }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="divXianShiP">
                        <div
                            class="xianshiP"
                            v-for="(item, index) in yulanList.other_config"
                            :key="index"
                        >
                            <p class="pOne">{{ item.value }}</p>
                            <p class="pTwo">{{ item.name }}</p>
                        </div>
                    </div>
                    <!--   <div class="divBorder">
            <div
              v-for="(item, index) in yulanList.imgs"
              :key="index"
              class="divImg"
              style="margin-top: 80px"
            >
              <img :src="item" style="width: 100%" />
            </div>
          </div>

          <div class="divConfig">
            <div style="margin-bottom: 50px">
              <el-button type="primary" style="margin: 10px" class="chanpxianhq"
                >产品详情</el-button
              >
              <p style="text-align: center; font-size: 26px">
                {{ yulanList.name }}
              </p>
            </div>

            <p
              v-for="(item, index) in yulanList.config"
              :key="index"
              class="divImg1"
              style="height: 50px; margin-left: 50px"
            >
              {{ item }}
            </p>
          </div>-->

                    <div
                        class="divBorder"
                        style="margin-top: 500px; border: 1px solid #ffffff"
                    >
                        <div
                            v-html="yulanList.content"
                            style="margin-top: 80px; text-align: center"
                        ></div>
                    </div>
                </div>
            </div>
        </div>

        <Footer></Footer>
    </div>
</template>

<script>
import { Header, Elevate, Footer } from "@components";
import LeftLian from "../components/lianXi/leftLian.vue";

import RightLian from "../components/lianXi/rightLian";

export default {
    name: "App",
    components: {
        Header,
        Elevate,
        Footer,
        LeftLian,
        RightLian
    },
    data() {
        return {
            allJuLi: 0,
            imgJuLi: 0, //  移动的距离
            dangqianIndex: 0, //  当前的索引值
            oneImg: require("../assets/doc/document.png"),
            downloadList2: [
                {
                    imgSrc: require("../assets/doc/document.png")
                },
                {
                    imgSrc: require("../assets/doc/xiazai.png")
                },
                {
                    imgSrc: require("../assets/doc/download.png")
                },
                {
                    imgSrc: require("../assets/doc/shequ.png")
                },
                {
                    imgSrc: require("../assets/doc/document.png")
                },
                {
                    imgSrc: require("../assets/doc/xiazai.png")
                },
                {
                    imgSrc: require("../assets/doc/download.png")
                },
                {
                    imgSrc: require("../assets/doc/shequ.png")
                }
            ],
            cpListOne: [],
            cpListTwo: [],
            shuru: "",
            a1: [],
            List: [1, 2],
            fenLeiList: [],
            chanPingList: [],
            product_id: "",
            falg: false,
            yulanList: [],
            zongList: {},
            nameFalg: true,
            menus: [
                {
                    text: "首页",
                    id: "app"
                },
                {
                    text: "产品商城",
                    id: "products"
                },

                {
                    text: "联系我们",
                    id: "about"
                },
                {
                    text: "管理平台",
                    id: "guanlipt"
                },
                {
                    text: "开发者文档",
                    id: "devdoc"
                }
            ],
            listData: [
                {
                    title: "API参考",
                    subTitle: "查看API详情",
                    imgSrc: require("../assets/doc/api.png")
                },
                {
                    title: "SDK下载",
                    subTitle: "下载WIF-OS的SDK",
                    imgSrc: require("../assets/doc/sdk.png")
                },
                {
                    title: "FAQ",
                    subTitle: "常见问题回答",
                    imgSrc: require("../assets/doc/question.png")
                }
            ],
            downloadList: [
                {
                    title: "产品文档",
                    subTitle: "查看在线文档",
                    imgSrc: require("../assets/doc/document.png")
                },
                {
                    title: "SDK 下载",
                    subTitle: "物联网管理SDK下载",
                    imgSrc: require("../assets/doc/xiazai.png")
                },
                {
                    title: "WIF-OS下载",
                    subTitle: "网飞安卓系统下载",
                    imgSrc: require("../assets/doc/download.png")
                },
                {
                    title: "社区中心",
                    subTitle: "前往开发者社区",
                    imgSrc: require("../assets/doc/shequ.png")
                }
            ]
        };
    },
    computed: {
        imgStyle() {
            this.imgJuLi = this.imgJuLi;

            return {
                transform: `translate3d(${this.imgJuLi}px, 0 , 0)`
            };
        }
    },
    methods: {
        dianjiShang(item) {
            let param = {
                id: item.id
            };

            this.$router.push({
                path: "/products",
                query: { appInfo: JSON.stringify(param) }
            });
        },
        chanp() {
            this.a1 = [];
            this.$router.push({
                path: "/products"
                //  query: { appInfo: JSON.stringify(param) },
            });
        },

        changeImg(item, index) {
            this.oneImg = item;
            this.dangqianIndex = index;
        },
        imgLeft() {
            if (this.dangqianIndex > 0) {
                this.dangqianIndex--;
                this.downloadList2.forEach((item, index) => {
                    if (this.dangqianIndex === index) {
                        this.oneImg = item;
                    }
                });
            }
            if (this.dangqianIndex >= 4) {
                let index = 0;
                const temp = window.setInterval(() => {
                    if (index < 33) {
                        this.imgJuLi += 2;
                        index++;
                        return;
                    } else {
                        window.clearInterval(temp);
                    }
                }, 10);
            }
            if (this.dangqianIndex === 0) {
                this.imgJuLi = 0;
            }
        },
        imgRight() {
            if (this.dangqianIndex < this.downloadList2.length - 1) {
                this.dangqianIndex++;
                this.downloadList2.forEach((item, index) => {
                    if (this.dangqianIndex === index) {
                        this.oneImg = item;
                    }
                });
                if (this.dangqianIndex >= 4) {
                    this.allJuLi = -66 * (this.dangqianIndex - 4);
                    let index = 0;
                    const temp = window.setInterval(() => {
                        if (index < 33) {
                            this.imgJuLi -= 2;
                            index++;
                            return;
                        } else {
                            window.clearInterval(temp);
                        }
                    }, 10);
                } else if (this.dangqianIndex === this.downloadList2.length) {
                    this.dangqianIndex = 0;
                    this.oneImg = this.downloadList2[0];
                    let index = 0;
                    const temp = window.setInterval(() => {
                        if (index < Math.abs(this.allJuLi / 2)) {
                            this.imgJuLi += 2;
                            index++;
                            return;
                        } else {
                            window.clearInterval(temp);
                        }
                    }, 1);
                }
                //  console.log(this.oneImg);
            }
        },
        chanpzx() {
            this.$router.push({
                path: "/products"
            });
        },
        zong() {
            let param = {
                id: this.yulanList.group_id
            };
            this.$router.push({
                path: "/products",
                query: { appInfo: JSON.stringify(param) }
            });
        },
        a(item) {
            if (item === "" || item === null || item === undefined) {
                return;
            }
            if (this.fenLeiList[item].product_list.length === 0) {
                this.a1 = [];
            }
        },
        getHot() {
            this.axios({
                url: "/index/product/getHot",
                method: "post",
                data: {}
            }).then(res => {
                this.chanPingList = res.data.data;
                let a = res.data.data;
                let b = res.data.data;
                // console.log(b);
                this.cpListOne = a.slice(0, 2);
                this.cpListTwo = b.slice(-2);
            });
        },
        chakan(scope) {
            // console.log(scope);
            this.nameFalg = true;
            this.product_id = scope.id;
            this.getDetail();
        },
        xuanze(item, item1) {
            this.nameFalg = true;
            this.product_id = item.product_id;
            this.getDetail();
        },
        getGroup() {
            this.axios({
                url: "/index/product/getGroup",
                method: "post",
                data: {}
            }).then(res => {
                this.fenLeiList = res.data.data;
                // console.log(res);
            });
        },
        getDetail() {
            this.axios({
                url: "/index/product/getDetail",
                method: "post",
                data: {
                    product_id: this.product_id
                }
            }).then(res => {
                //   console.log(res);
                this.falg = true;
                this.yulanList = res.data.data;
                this.downloadList2 = res.data.data.imgs;
                this.oneImg = res.data.data.imgs[0];
            });
        }
    },
    mounted() {
        this.getGroup();
        this.getHot();
        let appInfo = JSON.parse(this.$route.query.appInfo);
        this.product_id = JSON.parse(this.$route.query.appInfo).id;
        this.getDetail();
        let id1 = JSON.parse(this.$route.query.appInfo).id1;
    }
};
</script>
<style lang="less" module></style>

<style scoped>
.oneImgDiv {
    width: 100%;
    height: 300px;
    border-radius: 10px;
}
.pOne {
    font-size: 24px;
}
.pTwo {
    font-size: 16px;
    margin-top: 30px;
}
.xianshiP {
    border-right: 1px solid #000000;
    text-align: center;
    width: 25%;
    display: inline-block;
}
.xianshiP:last-of-type {
    border: 0px solid red;
}
.divXianShiP {
    position: absolute;
    top: 502px;
    width: 70%;
    height: 100px;
}

.product {
    color: #ffffff;
}
/deep/ .el-collapse-item__header.is-active {
    background-color: cornflowerblue;
}
/deep/ .el-collapse-item__header.is-active > .el-button {
    color: #fff;
}
.imgSec {
    border: 3px solid #e5e5e5;
    width: 60px;
    height: 60px;
}
.donwLoadList3 {
    position: relative;
    display: flex;
    left: 36px;
    width: 322px;
    overflow: hidden;
}
.donwLoadList {
    float: left;
    margin: 0 10px;
    cursor: pointer;
}
.rightOneP {
    color: rgb(12, 90, 170);
    font-size: 42px;
    text-align: center;
    margin-left: 30px;
}
.divDocLeft {
    width: 60%;
    float: left;
    margin-top: 20px;
}
.divDocRight {
    width: 40%;
    float: left;
    text-align: center;
    margin-top: 20px;
}
.xiangqDiv {
    display: block;
    font-size: 20px;
    text-align: left;
    margin-left: 2.5rem;
    color: #999;
}
</style>

<style scoped="scoped">
.page-section2 {
    overflow-y: scroll;
    min-height: 2200px;
    width: 1200px;
    margin: 0 auto;
    margin-bottom: 50px;
    padding-top: 80px;
}
.doc-container {
    margin-left: 10px;
    background: #fff;
    border-radius: 5px;

    margin-top: 30px;
    position: relative;
}
.left-contents {
    /* height:calc(100% - 88px); */
    height: 100%;
    overflow-y: scroll;
    width: 270px;
    float: left;
    padding: 10px 0;
    /* border-right: 1px solid rgb(235,236,237); */
}
.left-contents /deep/ .el-input {
    width: 90%;
    margin: 5%;
}
.doc-content {
    width: calc(100% - 300px);
    padding: 20px;

    float: right;
}
.el-tree {
    color: #000000;
    font-size: 16px;
}
.el-tree /deep/ .is-current {
    /* color: #0066ff; */
    background-color: rgba(0, 102, 255, 0.1);
}
.el-tree /deep/ .el-tree-node:focus > .el-tree-node__content {
    /* color: #0066ff; */
    background-color: rgba(0, 102, 255, 0.1);
}
.el-tree /deep/ .el-tree-node__content:hover {
    background-color: rgba(0, 102, 255, 0.1);
}
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    display: none;
    /* background-color: #F5F5F5; */
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #ffffff;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    /* background-color: #c8c8c8; */
    background-color: rgba(0, 102, 255, 0.4);
}

.item-container {
    width: 500px;
}
.item-title {
    font-size: 20px;
    font-weight: 600;
    background-color: rgb(228, 233, 238);
    border-radius: 2px;
    line-height: 40px;
    padding: 0 5px;
}

.item-content > div:first-child {
    background-color: rgb(248, 250, 252);
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 10px;
    width: 150px;
    border-right: 1px solid rgb(237, 239, 242);
    width: 150px;
}
.item-content > div:last-child {
    padding: 10px;
    width: 350px;
}
</style>

<style scoped>
.chanpxianhq {
    border: 0px solid #e5e5e5;
    background-color: rgb(255, 141, 26);
}
.divConfig {
    background-color: rgb(237, 237, 237);
}
.divImg {
    margin-left: 10px;
    display: inline-block;
    width: 48%;
    height: 420px;
}
.divImg1 {
    margin-left: 10px;
    display: inline-block;
    width: 40%;
    height: 420px;
}
.divBorder {
    border: 1px solid #e5e5e5;
}

.text {
    font-size: 14px;
}

.item {
    padding: 18px 0;
}
</style>

<style scoped>
/**移动端适配 */
/deep/ .el-carousel__button {
    width: 100px;
}

.btn2 {
    min-width: 185px;
    font-size: 2px;
    transform: scale(2);
    height: 40px;
    font-size: 13px;
    display: inline-block;
    margin-top: 66px;
    margin-left: 28%;
}
.page-section1 {
    padding-top: 120px;
    overflow-y: scroll;
    min-height: 2000px;
    width: 1350px;
    margin-bottom: 50px;
    margin: 0 auto;
}
.chanPingShiPeiList {
    margin-top: 20px;
    width: 40.5rem;
    display: inline-block;
    margin-left: 10px;
}
.xuanzhuan {
    float: right;
    background-color: red;
    font-weight: bolder;
    transform: rotate(25deg);
}
.zhanKaiTopShiPei {
    width: 98%;
    color: #fff;
    margin-left: 10px;
    line-height: 56px;
    background-color: rgb(42, 130, 228);
}

.biankuangShiPei {
    width: 98%;

    margin-left: 10px;
    text-align: left;
    font-size: 14px;
    border: 1px solid rgb(42, 130, 228);
    margin-bottom: 20px;
    height: 740px;
}
.pw {
    color: rgb(255, 141, 26);
    font-size: 36px;
    margin-left: 51.5%;
}
.bksp > p {
    color: rgb(255, 141, 26);
    margin-top: 20px;
    margin-left: 10px;
    font-size: 52px;
    line-height: 166px;
}
.contenDiv1 {
    margin-top: 10px;
    height: 25%;
    width: calc(100% - 600px);

    margin-left: 10px;
    display: inline-block;
}
/** */
.btn1 {
    min-width: 200px;
    font-size: 2px;
    transform: scale(0.75);
    font-size: 14px;
    margin-top: 10px;
}
/deep/ .el-collapse-item__arrow {
    margin: 0 28px 0 auto;
}
.text1 {
    text-decoration: underline;
    color: rgb(255, 141, 26);
}
.Pxiangq {
    color: red;
    margin-left: 10px;
}

/deep/ .el-submenu__icon-arrow {
    color: rgb(42, 130, 228);
}
/deep/ .el-icon-arrow-down {
    color: rgb(42, 130, 228);
}

/deep/ .el-collapse-item {
    border-bottom: 1px solid #e5e5e5;
}

/deep/ .el-collapse {
    width: 265px;

    height: 290px;
    border: 1px solid rgb(42, 130, 228);

    overflow-x: hidden;
}

/deep/ .el-collapse-item__content {
}

.neiRong {
    width: 60%;
    height: 200px;
}
.zhanKaiTop {
    color: #fff;
    line-height: 56px;
    height: 56px;
    width: 265px;
    background-color: rgb(42, 130, 228);
}
.xianshi {
    border: 1px solid rgb(42, 130, 228);
    width: 265px;
}

.biankuang {
    text-align: left;
    font-size: 14px;
    border: 1px solid rgb(42, 130, 228);
    width: 265px;
}
.biankuang > p {
    color: rgb(42, 130, 228);

    margin-left: 16%;
    margin-top: 10px;
}
.pName {
    float: left;
    margin-right: 20%;
    text-align: center;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: bolder;
}
</style>

<style scoped>
@media screen and (min-width: 1100px) {
    /* .daohang{
    display: none;
  }*/
    .page-section1 {
        display: none;
    }
}
@media screen and (max-width: 1100px) {
    ::v-deep .el-input__inner {
        height: 80px;
        font-size: 24px;
        color: #000000;
    }
    .page-section2 {
        display: none;
    }
    /deep/ .el-input {
        font-size: 26px;
    }
}
</style>
